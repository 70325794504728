import { authUrl, JWT_TOKEN_NAME } from '../config';

export const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${authUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token }) => {
        console.log(access_token);
        localStorage.setItem(JWT_TOKEN_NAME, access_token);
      });
  },
  logout: () => {
    localStorage.removeItem(JWT_TOKEN_NAME);
    return Promise.resolve();
  },
  checkAuth: () => {
    if (localStorage.getItem(JWT_TOKEN_NAME) !== null) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  checkError: error => Promise.resolve(),
  getPermissions: params => Promise.resolve(),
};
