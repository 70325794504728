import { fetchUtils } from 'react-admin';
import { adminUrl, JWT_TOKEN_NAME } from '../config';
import jsonServerProvider from 'ra-data-json-server';
import { addApkUpload } from './addApkUpload';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  options.user = {
    authenticated: true,
    token: 'bearer ' + localStorage.getItem(JWT_TOKEN_NAME),
  };

  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = addApkUpload(
  jsonServerProvider(adminUrl, httpClient)
);
// export const dataProvider = jsonServerProvider(adminUrl, httpClient);
