import prettyBytes from 'pretty-bytes';
import React from 'react';
import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  FunctionField,
  List,
  required,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  UrlField,
} from 'react-admin';
import EditToolbarOnlySave from '../../components/EditToolbarOnlySave';
import { DeleteApplicationButton } from './actions/DeleteApplicationButton';
// let fieldsRefs = {};
// const ListFilter = props => (
//   <Filter {...props}>
//     <BooleanInput label="Mostrar removidos" source="deleted" alwaysOn />
//   </Filter>
// );

const mdmApplicationsRowStyle = (record, index) => ({
  backgroundColor:
    record?.function === 'DUAL_CLIENT'
      ? '#D8F1FF'
      : record?.function === 'DUAL_CASHIER'
      ? '#FFFDB9'
      : '',
});

export const MdmApplicationsDataGrid = (
  <Datagrid rowClick="show" rowStyle={mdmApplicationsRowStyle}>
    <TextField source="package" />
    <TextField source="versionCode" />
    <TextField source="versionName" />
    <FunctionField
      label="File Size"
      render={(record) => (record.fileSize ? prettyBytes(record.fileSize) : '')}
      allowEmpty={true}
    />
    <BooleanField source="deleted" />
    <DateField source="createdAt" locales="pt-BR" />
    {/* <DateField  source="updatedAt" locales="pt-BR" /> */}
    {/* <BooleanField source="deleted" /> */}
    {/* <TextField source="deletedAt" /> */}
  </Datagrid>
);

export const MdmApplicationsList = (props: any) => (
  <List
    perPage={25}
    bulkActionButtons={false}
    // filters={<ListFilter />}
    // filterDefaultValues={{ deleted: false }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    {MdmApplicationsDataGrid}
  </List>
);

export const MdmApplicationsShow = (props: any) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <TextField source="package" />
      <TextField source="versionCode" />
      <TextField source="versionName" />
      <TextField source="hash" />
      <UrlField source="url" />
      <FunctionField
        label="File Size"
        render={(record) =>
          record.fileSize ? prettyBytes(record.fileSize) : ''
        }
        allowEmpty={true}
      />
      <BooleanField source="deleted" />
      <TextField source="id" />
      <DateField source="createdAt" showTime={true} locales="pt-BR" />
      <DateField source="deletedAt" showTime={true} locales="pt-BR" />
      <DeleteApplicationButton label={'Deletar'} {...props} />
    </SimpleShowLayout>
  </Show>
);

export const MdmApplicationsCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm toolbar={<EditToolbarOnlySave />} redirect="show">
      <FileInput
        maxSize={100000 * 1000}
        multiple={false}
        source="files"
        label="Apk"
        accept="application/vnd.android.package-archive"
      >
        <FileField source="src" title="name" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export const MdmApplicationsEdit = (props: any) => (
  <Edit undoable={false} title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput
        source="package"
        // defaultValue="Principal"
        validate={[required()]}
      />
    </SimpleForm>
  </Edit>
);

const Title = ({ record }: any) => {
  return <span>Configuration {record ? `"${record.name}"` : ''}</span>;
};

