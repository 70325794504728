import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useNotify, useDataProvider } from 'react-admin';
import AlertDialog from '../../../components/AlertDialog';
import { useHistory } from 'react-router-dom';

export const DeleteApplicationButton = ({ label, record }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const data = record;
  const remove = async() => {
    dataProvider
      .delete('mdm-applications', {
        id: data.id,
      })
      .then(() => {
        notify('Aplicativo deletado com sucesso!');
        history.push('/mdm-applications');
      })
      .catch((error) => {
        notify(`Erro ao deletar aplicativo: ${error.message}`);
      })
      .finally(() => {
        setIsOpen(false);
      });
  };
  if (!data) return null;
  return (
    <React.Fragment>
      <Button
        onClick={() => {
          setIsOpen(true);
          setIsVisible(record.configurations.lenght > 0);
        }}
        variant="contained"
        color="secondary"
        style={{ marginTop: '20px' }}
        disabled={isVisible || record.deleted === true}
      >
        {label}
      </Button>
      <AlertDialog
        title={`${label} Aplicativo`}
        isOpen={isOpen}
        handleConfirm={remove}
        handleClose={() => {setIsOpen(false)}}
      >
        Confirma a deleção do aplicativo?
      </AlertDialog>
    </React.Fragment>
  );
};
