import { PUBLIC_URL } from '../config';

export const loadAndSetStageTitle = (stage) => {
  if (stage !== 'production') {
    document.title = `MDM [${stage}]`;
  }
};

export const switchFavIcon = (stageName: string) => {
  const iconUrl =
    stageName === 'development'
      ? 'favicon_development.png'
      : stageName === 'sandbox'
      ? 'favicon_sandbox.png'
      : stageName === 'alpha'
      ? 'favicon_alpha.png'
      : 'favicon_production.png';

  let favicon: HTMLLinkElement;
  // @ts-ignore
  favicon = document.querySelector("link[rel~='icon']");

  favicon.href = `${PUBLIC_URL}/${iconUrl}`;
};
