import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useNotify, useDataProvider } from 'react-admin';
import AlertDialog from '../../../components/AlertDialog';

export const CommandDeviceButton = ({ label, action, record }) => {
  const [isOpen, setIsOpen] = useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const create = () =>
    dataProvider
      .create('mdm-commands', {
        data: {
          deviceId: record.id,
          type: action,
        },
      })
      .then(() => {
        notify('Comando criado com sucesso!');
      })
      .catch(error => {
        notify(`Erro ao criar o comando: ${error.message}`);
      })
      .finally(() => {
        setIsOpen(false);
      });

  if (!record) return null;

  return (
    <React.Fragment>
      <Button
        onClick={() => setIsOpen(true)}
        variant="outlined"
        color="secondary"
        style={{ marginTop: '20px', marginLeft: '10px' }}
      >
        {label}
      </Button>
      <AlertDialog
        title={`${label} dispositivo`}
        isOpen={isOpen}
        handleConfirm={create}
        handleClose={() => setIsOpen(false)}
      >
        Confirme a execução do comando.
      </AlertDialog>
    </React.Fragment>
  );
};
