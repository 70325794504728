import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  required,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

const usersRowStyle = (record, index) => ({
  backgroundColor: record.deleted ? 'rgba(208,2,27,0.8)' : '',
});

export const UserList = (props: any) => (
  <List
    perPage={25}
    bulkActionButtons={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="show" rowStyle={usersRowStyle}>
      <TextField source="email" />
      <BooleanField source="isAdmin" />
      <DateField source="createdAt" locales="pt-BR" />
    </Datagrid>
  </List>
);

export const UserShow = (props: any) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="email" />
        <BooleanField source="isAdmin" />
        <TextField source="timezone" />
        <TextField source="id" />
        <DateField source="createdAt" showTime={true} locales="pt-BR" />
        <DateField source="updatedAt" showTime={true} locales="pt-BR" />
        <BooleanField source="deleted" />
        <DateField source="deletedAt" showTime={true} locales="pt-BR" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const UserCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="email" validate={[required()]} />
      <TextInput
        source="password"
        type={'password'}
        autoComplete={'off'}
        validate={[required()]}
      />
      <BooleanInput source="isAdmin" />
    </SimpleForm>
  </Create>
);

export const UserEdit = (props: any) => (
  <Edit undoable={false} title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="email" validate={[required()]} />
      <TextInput
        type={'password'}
        autoComplete={'off'}
        source="currentPassword"
        validate={[required()]}
      />
      <TextInput type={'password'} autoComplete={'off'} source="password" />
      <BooleanInput source="isAdmin" />
    </SimpleForm>
  </Edit>
);

const Title = ({ record }: any) => {
  return <span>User {record ? `"${record.email}"` : ''}</span>;
};
