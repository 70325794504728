import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  Pagination,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

// let fieldsRefs = {};

// const ListFilter = props => (
//   <Filter {...props}>
//     <BooleanInput label="Mostrar removidos" source="deleted" alwaysOn />
//   </Filter>
// );

const mdmCommandRowStyle = (record, index) => ({
  backgroundColor:
    record?.function === 'DUAL_CLIENT'
      ? '#D8F1FF'
      : record?.function === 'DUAL_CASHIER'
      ? '#FFFDB9'
      : '',
});

export const MdmCommandDataGridOnDevice = (
  <Datagrid rowClick="show" rowStyle={mdmCommandRowStyle}>
    <DateField
      label="Created at"
      source="createdAt"
      showTime={true}
      locales="pt-BR"
    />

    <TextField label="Created at" source="createdAtRelative" />

    <TextField source="type" label="Type" />
    <TextField source="status" label="Status" />
  </Datagrid>
);

export const MdmCommandDataGrid = (
  <Datagrid rowClick="show" rowStyle={mdmCommandRowStyle}>
    <ReferenceField
      source="groupId"
      reference="mdm-device-groups"
      linkType="show"
      allowEmpty={true}
    >
      <TextField source="name" />
    </ReferenceField>

    <ReferenceField source="deviceId" reference="mdm-devices" linkType="show">
      <TextField source="name" />
    </ReferenceField>

    <DateField
      label="Created At"
      source="createdAt"
      showTime={true}
      locales="pt-BR"
    />

    <TextField label="Created At" source="createdAtRelative" />

    <TextField source="type" label="Type" />
    <TextField source="status" label="Status" />
  </Datagrid>
);

export const MdmCommandList = (props: any) => (
  <List
    perPage={25}
    bulkActionButtons={false}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    // filters={<ListFilter />}
    // filterDefaultValues={{ deleted: false }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    {MdmCommandDataGrid}
  </List>
);

export const MdmCommandShow = (props: any) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <ReferenceField
        source="groupId"
        reference="mdm-device-groups"
        linkType="show"
        allowEmpty={true}
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="deviceId" reference="mdm-devices" linkType="show">
        <TextField source="name" />
      </ReferenceField>

      <DateField
        label="Last Update"
        source="createdAt"
        showTime={true}
        locales="pt-BR"
      />

      <TextField label="Last Update" source="createdAtRelative" />

      <TextField source="type" label="Type" />
      <TextField source="status" label="Status" />
    </SimpleShowLayout>
  </Show>
);

const Title = ({ record }: any) => {
  return <span>Command {record ? `"${record.createdAt}"` : ''}</span>;
};
