import { v4 as uuidv4 } from 'uuid';

export const baseUrl = process.env.REACT_APP_API_URL || 'http://127.0.0.1:3000';

export const STAGE = process.env.REACT_APP_STAGE || 'development';
export const PUBLIC_URL = process.env.PUBLIC_URL || '';

export const authUrl = baseUrl;

export const adminUrl = `${baseUrl}/admin`;

export const JWT_TOKEN_NAME = 'mdm-token';

export const DEVICE_UUID_NAME = 'mdm-device-uuid';

export let deviceUUID = localStorage.getItem(DEVICE_UUID_NAME);

export function loadConfig() {
  if (!deviceUUID) {
    deviceUUID = uuidv4();
    localStorage.setItem(DEVICE_UUID_NAME, `${deviceUUID}`);
    console.log('Device UUID: ' + deviceUUID);
  }
}
