import DeviceUnknownIcon from '@material-ui/icons/Devices';
import UserIcon from '@material-ui/icons/Group';
import KeyIcon from '@material-ui/icons/Keyboard';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { authProvider } from './auth-provider/auth-provider';
import { dataProvider } from './data-provider';
import {
  ApiKeyCreate,
  ApiKeyEdit,
  ApiKeyList,
  ApiKeyShow,
} from './resources/api-keys';
import {
  MdmDeviceCreate,
  MdmDeviceEdit,
  MdmDeviceList,
  MdmDeviceShow,
} from './resources/mdm/devices';
import {
  MdmApplicationsCreate,
  MdmApplicationsList,
  MdmApplicationsShow,
} from './resources/mdm/mdm-applications';
import { MdmCommandList, MdmCommandShow } from './resources/mdm/mdm-commands';
import {
  MdmConfigurationsCreate,
  MdmConfigurationsEdit,
  MdmConfigurationsList,
  MdmConfigurationsShow,
} from './resources/mdm/mdm-configurations';
import {
  MdmDeviceGroupsCreate,
  MdmDeviceGroupsEdit,
  MdmDeviceGroupsList,
  MdmDeviceGroupsShow,
} from './resources/mdm/mdm-device-groups';
import {
  MdmDeviceStatsList,
  MdmDeviceStatsShow,
} from './resources/mdm/mdm-device-stats';
import { UserCreate, UserEdit, UserList, UserShow } from './resources/users';
import { theme } from './theme/theme';
import { switchFavIcon, loadAndSetStageTitle } from './theme/utils';
import { STAGE } from './config';
import MyLayout from './layout/MyLayout';

// const messages = {
//   pt: { ...portugueseMessages, ...errorMessages, ...appMessages },
// };
// @ts-ignore
// const i18nProvider = locale => messages[locale];

loadAndSetStageTitle(STAGE);
switchFavIcon(STAGE);

const App = () => (
  <Admin
    locale="pt"
    // i18nProvider={i18nProvider}
    theme={theme}
    // loginPage={LoginPage}
    authProvider={authProvider}
    dataProvider={dataProvider}
    appLayout={MyLayout}
    // appLayout={MyLayout}
  >
    <Resource
      options={{ label: 'Grupos' }}
      name="mdm-device-groups"
      list={MdmDeviceGroupsList}
      show={MdmDeviceGroupsShow}
      edit={MdmDeviceGroupsEdit}
      create={MdmDeviceGroupsCreate}
      icon={DeviceUnknownIcon}
    />
    <Resource
      options={{ label: 'Dispositivos' }}
      name="mdm-devices"
      list={MdmDeviceList}
      show={MdmDeviceShow}
      edit={MdmDeviceEdit}
      create={MdmDeviceCreate}
      icon={DeviceUnknownIcon}
    />
    <Resource
      options={{ label: 'Status' }}
      name="mdm-device-stats"
      list={MdmDeviceStatsList}
      show={MdmDeviceStatsShow}
      // edit={MdmDeviceEdit}
      // create={MdmDeviceCreate}
      icon={DeviceUnknownIcon}
    />
    <Resource
      options={{ label: 'Commands' }}
      name="mdm-commands"
      list={MdmCommandList}
      show={MdmCommandShow}
      // edit={MdmDeviceEdit}
      // create={MdmDeviceCreate}
      icon={DeviceUnknownIcon}
    />
    <Resource
      options={{ label: 'Configurações' }}
      name="mdm-configurations"
      list={MdmConfigurationsList}
      show={MdmConfigurationsShow}
      edit={MdmConfigurationsEdit}
      create={MdmConfigurationsCreate}
      icon={DeviceUnknownIcon}
    />
    <Resource
      options={{ label: 'Aplicativos' }}
      name="mdm-applications"
      list={MdmApplicationsList}
      show={MdmApplicationsShow}
      // edit={MdmApplicationsEdit}
      create={MdmApplicationsCreate}
      icon={DeviceUnknownIcon}
    />
    <Resource
      name="mdm-users"
      options={{ label: 'Usuários' }}
      list={UserList}
      show={UserShow}
      edit={UserEdit}
      create={UserCreate}
      icon={UserIcon}
    />
    <Resource
      name="mdm-api-keys"
      options={{ label: 'Chaves de API' }}
      list={ApiKeyList}
      show={ApiKeyShow}
      edit={ApiKeyEdit}
      create={ApiKeyCreate}
      icon={KeyIcon}
    />
  </Admin>
);

export default App;
