import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component<{
  isOpen: boolean;
  handleClose: any;
  handleConfirm: any;
  title: string;
}> {
  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="secondary">
            Não
          </Button>
          <Button onClick={this.props.handleConfirm} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialog;
