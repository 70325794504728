import { CardActions } from '@material-ui/core';
import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  FunctionField,
  List,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  Show,
  SimpleForm,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import EditToolbarOnlySave from '../../components/EditToolbarOnlySave';
import { QRCodeField } from '../../components/QRCodeField';
import { CommandDeviceButton } from './actions/CommandDeviceButton';
import { TextFieldLinkShow } from '../../components/TextFieldLinkShow';
import { MdmCommandDataGridOnDevice } from './mdm-commands';
import { MdmDeviceStatsDataGridNoDevice } from './mdm-device-stats';

let fieldsRefs = {};

const DeviceShowActions = ({ basePath, data, ...props }: any) => (
  <CardActions
    style={{
      display: 'inline-block',
      float: 'right',
    }}
  >
    <CommandDeviceButton
      label="Reiniciar dispositivo"
      action="RESTART_DEVICE"
      record={data}
      {...props}
    />

    <CommandDeviceButton
      label="Reiniciar aplicativo"
      action="RESTART_KIOSK_APPLICATION"
      record={data}
      {...props}
    />

    <CommandDeviceButton
      label="Desbloquear dispositivo"
      action="UNLOCK_DEVICE"
      record={data}
      {...props}
    />
    {/* <CommandDeviceButton
      label="Atualizar systema operacional"
      action="RUN_SYSTEM_UPDATE"
      record={data}
      {...props}
    /> */}
  </CardActions>
);

const ListFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      label="Grupo"
      alwaysOn
      resettable
      source="group"
      reference="mdm-device-groups"
      filter={{ deleted: false }}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      {/* <SelectInput /> */}
      <AutocompleteInput
        optionText="name"
        resettable
        // shouldRenderSuggestions={val => {
        //   return val.trim().length > 0;
        // }}
      />
    </ReferenceInput>
    <BooleanInput label="Mostrar removidos" source="deleted" alwaysOn />
  </Filter>
);

const deviceRowStyle = (record: any, index: any) => ({
  backgroundColor:
    record?.function === 'DUAL_CLIENT'
      ? '#D8F1FF'
      : record?.function === 'DUAL_CASHIER'
      ? '#FFFDB9'
      : '',
});

export const MdmDeviceDataGrid = (
  <Datagrid rowStyle={deviceRowStyle}>
    <ReferenceField
      source="groupId"
      reference="mdm-device-groups"
      linkType="show"
      allowEmpty={true}
    >
      <TextField source="name" />
    </ReferenceField>
    <TextFieldLinkShow source="name" />
    <TextFieldLinkShow source="imei" />
    <TextFieldLinkShow source="chip" />
    <BooleanField source="deleted" />
    <DateField source="createdAt" locales="pt-BR" />
  </Datagrid>
);

export const MdmDeviceList = (props: any) => (
  <List
    pagination={<Pagination rowsPerPageOptions={[10, 25]} />}
    perPage={25}
    bulkActionButtons={false}
    filters={<ListFilter />}
    filterDefaultValues={{ deleted: false }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    {MdmDeviceDataGrid}
  </List>
);

export const MdmDeviceShow = (props: any) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <ReferenceField
          source="groupId"
          reference="mdm-device-groups"
          linkType="show"
          allowEmpty={true}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="imei" />
        <TextField source="chip" />
        <BooleanField source="deleted" />
        <TextField source="id" />
        <DateField source="createdAt" showTime={true} locales="pt-BR" />
        <DateField source="updatedAt" showTime={true} locales="pt-BR" />
      </Tab>
      <Tab label="MDM - Last stats">
        <DeviceShowActions />
        <DateField
          label="Last Update"
          source="lastStats.createdAt"
          showTime={true}
          locales="pt-BR"
        />
        <TextField label="Last Update" source="lastStats.createdAtRelative" />
        <BooleanField source="lastStats.locked" label="Locked" />
        <BooleanField source="lastStats.wifiConnected" label="Wifi connected" />
        <BooleanField source="lastStats.charging" label="Charging" />
        <TextField source="lastStats.batteryLevel" label="Battery level" />
        <TextField source="lastStats.location" label="Location" />
        <TextField source="chip" />

        <FunctionField
          source="lastStats.location"
          label="Location"
          render={(record) =>
            record.lastStats && record.lastStats.location ? (
              <a
                rel="noreferrer"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                href={`https://maps.google.com/?q=${record.lastStats.location.lat},${record.lastStats.location.long}`}
              >
                Abrir no Google Maps
              </a>
            ) : (
              ''
            )
          }
        />

        <ReferenceArrayField
          label="Installed applications"
          reference="mdm-applications"
          source="lastStats.installedApplicationsIds"
        >
          <SingleFieldList linkType="show">
            <ChipField source="packageAndVersion" />
          </SingleFieldList>
        </ReferenceArrayField>

        <FunctionField
          source="lastStats.lastDataReceived"
          label="Dados recebidos"
          render={(record) => (
            <pre>
              {record.lastStats
                ? JSON.stringify(record.lastStats.lastDataReceived, null, 4)
                : null}
            </pre>
          )}
          allowEmpty={true}
        />
      </Tab>
      <Tab label="MDM - All Stats" path="mdm-device-stats">
        <ReferenceManyField
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
          reference="mdm-device-stats"
          target="device"
          addLabel={false}
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          {MdmDeviceStatsDataGridNoDevice}
        </ReferenceManyField>
      </Tab>
      <Tab label="MDM - Commands" path="mdm-commands">
        <ReferenceManyField
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
          reference="mdm-commands"
          target="device"
          addLabel={false}
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          {MdmCommandDataGridOnDevice}
        </ReferenceManyField>
      </Tab>
      <Tab label="MDM - SENHA">
        <FunctionField
          source="passwords"
          label="MDM (Senhas)"
          render={(record) => (
            <pre>{JSON.stringify(record.passwords, null, 4)}</pre>
          )}
          allowEmpty={true}
        />
      </Tab>
      <Tab label="MDM - JSON">
        <FunctionField
          source="mdmJson"
          label="MDM (JSON)"
          render={(record) => (
            <pre>{JSON.stringify(record.mdmJson, null, 4)}</pre>
          )}
          allowEmpty={true}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const MdmDeviceCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm toolbar={<EditToolbarOnlySave />} redirect="show">
      <ReferenceInput
        source="groupId"
        reference="mdm-device-groups"
        perPage={500}
        filter={{ deleted: false }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        allowEmpty
      >
        <SelectInput source="name" />
      </ReferenceInput>
      <TextInput
        source="name"
        // defaultValue="Principal"
        validate={[required()]}
      />
      <TextInput
        source="imei"
        // defaultValue="Principal"
        validate={[required()]}
      />
      <QRCodeField fields={fieldsRefs} field="imei" />
    </SimpleForm>
  </Create>
);

export const MdmDeviceEdit = (props: any) => (
  <Edit undoable={false} title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        source="groupId"
        reference="mdm-device-groups"
        perPage={500}
        filter={{ deleted: false }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        allowEmpty
      >
        <SelectInput source="name" />
      </ReferenceInput>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="imei" validate={[required()]} />
      <QRCodeField fields={fieldsRefs} field="imei" />
    </SimpleForm>
  </Edit>
);

const Title = ({ record }: any) => {
  return <span>Device {record ? `"${record.name}"` : ''}</span>;
};
