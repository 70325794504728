import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import EditToolbarOnlySave from '../../components/EditToolbarOnlySave';
import { MdmDeviceDataGrid } from './devices';

// let fieldsRefs = {};

const ListFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Configuração"
      alwaysOn
      resettable
      source="configuration"
      reference="mdm-configurations"
      filter={{ deleted: false }}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      {/* <SelectInput /> */}
      <AutocompleteInput
        optionText="name"
        resettable
        // shouldRenderSuggestions={val => {
        //   return val.trim().length > 0;
        // }}
      />
    </ReferenceInput>
    <BooleanInput label="Mostrar removidos" source="deleted" alwaysOn />
  </Filter>
);

const mdmDeviceGroupsRowStyle = (record, index) => ({
  backgroundColor:
    record?.function === 'DUAL_CLIENT'
      ? '#D8F1FF'
      : record?.function === 'DUAL_CASHIER'
      ? '#FFFDB9'
      : '',
});

export const MdmDeviceGroupsDataGrid = (
  <Datagrid rowClick="show" rowStyle={mdmDeviceGroupsRowStyle}>
    <TextField source="name" />
    <ReferenceField
      source="configurationId"
      reference="mdm-configurations"
      linkType="show"
      allowEmpty={true}
    >
      <TextField source="name" />
    </ReferenceField>
    <DateField source="createdAt" locales="pt-BR" />
    {/* <DateField  source="updatedAt" locales="pt-BR" /> */}
    {/* <BooleanField source="deleted" /> */}
    {/* <TextField source="deletedAt" /> */}
  </Datagrid>
);

export const MdmDeviceGroupsList = (props: any) => (
  <List
    perPage={25}
    bulkActionButtons={false}
    filters={<ListFilter />}
    filterDefaultValues={{ deleted: false }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    {MdmDeviceGroupsDataGrid}
  </List>
);

export const MdmDeviceGroupsShow = (props: any) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <ReferenceField
          source="configurationId"
          reference="mdm-configurations"
          linkType="show"
          allowEmpty={true}
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="deleted" />
        <TextField source="id" />
        <DateField source="createdAt" showTime={true} locales="pt-BR" />
        <DateField source="updatedAt" showTime={true} locales="pt-BR" />
      </Tab>
      <Tab label="Dispositivos" path="mdm-device-groups">
        <ReferenceManyField
          pagination={<Pagination />}
          reference="mdm-devices"
          target="group"
          addLabel={false}
        >
          {MdmDeviceDataGrid}
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const MdmDeviceGroupsCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm toolbar={<EditToolbarOnlySave />} redirect="show">
      <TextInput
        source="name"
        // defaultValue="Principal"
        validate={[required()]}
      />
      <ReferenceInput
        source="configurationId"
        reference="mdm-configurations"
        filter={{ deleted: false }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        allowEmpty={true}
        // validate={[required()]}
      >
        <AutocompleteInput
          optionText="name"
          // shouldRenderSuggestions={val => {
          //   return val.trim().length > 0;
          // }}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const MdmDeviceGroupsEdit = (props: any) => (
  <Edit undoable={false} title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput
        source="name"
        // defaultValue="Principal"
        validate={[required()]}
      />
      <ReferenceInput
        source="configurationId"
        reference="mdm-configurations"
        filter={{ deleted: false }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        allowEmpty={true}
        // validate={[required()]}
      >
        <SelectInput source="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const Title = ({ record }: any) => {
  return <span>Device Group {record ? `"${record.name}"` : ''}</span>;
};
