import React from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin';

// let fieldsRefs = {};

// const ListFilter = props => (
//   <Filter {...props}>
//     <BooleanInput label="Mostrar removidos" source="deleted" alwaysOn />
//   </Filter>
// );

const mdmDeviceStatsRowStyle = (record, index) => ({
  backgroundColor:
    record?.function === 'DUAL_CLIENT'
      ? '#D8F1FF'
      : record?.function === 'DUAL_CASHIER'
      ? '#FFFDB9'
      : '',
});

export const MdmDeviceStatsDataGridNoDevice = (
  <Datagrid rowClick="show" rowStyle={mdmDeviceStatsRowStyle}>
    <DateField
      label="Last Update"
      source="createdAt"
      showTime={true}
      locales="pt-BR"
    />

    <TextField label="Last Update" source="createdAtRelative" />

    <BooleanField source="locked" label="Locked" />
    <BooleanField source="wifiConnected" label="Wifi connected" />
    <BooleanField source="charging" label="Charging" />
    <TextField source="batteryLevel" label="Battery level" />
    <TextField source="chip" />
  </Datagrid>
);

export const MdmDeviceStatsDataGrid = (
  <Datagrid rowClick="show" rowStyle={mdmDeviceStatsRowStyle}>
    <ReferenceField
      source="groupId"
      reference="mdm-device-groups"
      linkType="show"
      allowEmpty={true}
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="deviceId" reference="mdm-devices" linkType="show">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      source="deviceId"
      reference="mdm-devices"
      linkType="show"
      label="IMEI"
    >
      <TextField source="imei" label="IMEI" />
    </ReferenceField>
    <TextField source="chip" />

    <DateField
      label="Last Update"
      source="createdAt"
      showTime={true}
      locales="pt-BR"
    />

    <TextField label="Last Update" source="createdAtRelative" />

    <BooleanField source="locked" label="Locked" />
    <BooleanField source="wifiConnected" label="Wifi connected" />
    <BooleanField source="charging" label="Charging" />
    <TextField source="batteryLevel" label="Battery level" />
  </Datagrid>
);

export const MdmDeviceStatsList = (props: any) => (
  <List
    perPage={25}
    bulkActionButtons={false}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    // filters={<ListFilter />}
    // filterDefaultValues={{ deleted: false }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    {MdmDeviceStatsDataGrid}
  </List>
);

export const MdmDeviceStatsShow = (props: any) => (
  <Show title={<Title />} {...props}>
    <SimpleShowLayout>
      <ReferenceField
        source="groupId"
        reference="mdm-device-groups"
        linkType="show"
        allowEmpty={true}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="deviceId" reference="mdm-devices" linkType="show">
        <TextField source="name" />
      </ReferenceField>

      <DateField
        label="Last Update"
        source="createdAt"
        showTime={true}
        locales="pt-BR"
      />

      <TextField label="Last Update" source="createdAtRelative" />

      <BooleanField source="locked" label="Locked" />
      <BooleanField source="wifiConnected" label="Wifi connected" />
      <BooleanField source="charging" label="Charging" />
      <TextField source="batteryLevel" label="Battery level" />
      <TextField source="location" label="Location" />
      <TextField source="chip" />
      <FunctionField
        source="location"
        label="Location"
        render={(record) =>
          record.location ? (
            <a
              // eslint-disable-next-line react/jsx-no-target-blank
              rel="noreferrer"
              target="_blank"
              href={`https://maps.google.com/?q=${record.location.lat},${record.location.long}`}
            >
              Abrir no Google Maps
            </a>
          ) : (
            ''
          )
        }
      />

      <ReferenceArrayField
        label="Installed applications"
        reference="mdm-applications"
        source="installedApplicationsIds"
      >
        <SingleFieldList linkType="show">
          <ChipField source="packageAndVersion" />
        </SingleFieldList>
      </ReferenceArrayField>

      <FunctionField
        source="lastDataReceived"
        label="Dados recebidos"
        render={(record) => (
          <pre>{JSON.stringify(record.lastDataReceived, null, 4)}</pre>
        )}
        allowEmpty={true}
      />
    </SimpleShowLayout>
  </Show>
);

const Title = ({ record }: any) => {
  return <span>Stats {record ? `"${record.createdAt}"` : ''}</span>;
};
