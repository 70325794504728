import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4f5b62',
      main: '#4caf50',
      dark: '#000a12',
      contrastText: '#fff',
    },
    secondary: {
      light: '#80e27e',
      main: '#4caf50',
      dark: '#087f23',
      contrastText: '#fff',
    },
    background: {
      // default: '#000',
    },
  },
  typography: {
    fontSize: 16,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    // useNextVariants: true,
    fontFamily: [
      // 'Whitney',
      'Helvetica Neue',
      'Segoe UI',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      fontWeight: 500,
      letterSpacing: '1.25px',
    },
  },
});
