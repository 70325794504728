import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  FunctionField,
  List,
  required,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import QRCode from 'qrcode.react';
import { baseUrl } from '../config';

const usersRowStyle = (record, index) => ({
  backgroundColor: record.deleted ? 'rgba(208,2,27,0.8)' : '',
});

export const ApiKeyList = (props: any) => (
  <List
    perPage={25}
    bulkActionButtons={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="show" rowStyle={usersRowStyle}>
      <TextField source="name" />
      <TextField source="key" />
      <BooleanField source="canManageMdm" />
      <BooleanField source="canSyncDevices" />
      <DateField source="createdAt" locales="pt-BR" />
    </Datagrid>
  </List>
);

export const ApiKeyShow = (props: any) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <FunctionField
          source="url"
          label="API endpoint"
          render={record =>
            record.canSyncDevices ? (
              <div>{baseUrl}/mdm/device/sync</div>
            ) : (
              <div>{baseUrl}</div>
            )
          }
          allowEmpty={true}
        />
        <TextField source="key" />
        <BooleanField source="canManageMdm" />
        <BooleanField source="canSyncDevices" />
        <FunctionField
          source="key"
          label="QRCode de associação"
          render={record =>
            record.canSyncDevices ? (
              <QRCode
                value={JSON.stringify({
                  key: record.key,
                  url: baseUrl,
                })}
                size={256}
              />
            ) : (
              <div>Sem permissão se sincronização</div>
            )
          }
          allowEmpty={true}
        />

        <DateField source="createdAt" showTime={true} locales="pt-BR" />
        <BooleanField source="deleted" />
        <DateField source="deletedAt" showTime={true} locales="pt-BR" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ApiKeyCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
      <BooleanInput
        source="canManageMdm"
        label="Can manage MDM (for use by backend services)"
      />
      <BooleanInput
        source="canSyncDevices"
        label="Can sync devices (for use in the device)"
      />
    </SimpleForm>
  </Create>
);

export const ApiKeyEdit = (props: any) => (
  <Edit undoable={false} title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
      <BooleanInput
        source="canManageMdm"
        label="Can manage MDM (for use by backend services)"
      />
      <BooleanInput
        source="canSyncDevices"
        label="Can sync devices (for use in the device)"
      />
    </SimpleForm>
  </Edit>
);

const Title = ({ record }: any) => {
  return <span>ApiKey {record ? `"${record.id}"` : ''}</span>;
};
