import React from 'react';
import { Link } from "react-router-dom";

type Props = {
    source: string;
    record?: any;
    basePath?: string;
};

export const TextFieldLinkShow = ({ source, record, basePath }: Props) => {
    if (!record || !basePath) return null;
    const to = `${basePath}/${record.id}/show`;
    return (
        <Link style={styles.link} to={to}>
            {record[source]}
        </Link>
    );
};

const styles = {
    link: {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
        display: 'block'
    }
};
