import { Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { AppBar, Layout } from 'react-admin';
import { STAGE } from '../config';

const styles: any = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
};

const appBarBackgroundColor =
  STAGE === 'development'
    ? '#FFE132'
    : STAGE === 'sandbox'
    ? '#E98F00'
    : STAGE === 'alpha'
    ? '#B82C9F'
    : '#4caf50';

class MyAppBar extends Component<any, any> {
  render() {
    return (
      <AppBar {...this.props} style={{ background: appBarBackgroundColor }}>
        <Typography
          style={{ fontWeight: 'normal', fontSize: '16px' }}
          color="inherit"
          className={this.props.classes.title}
        >
          {STAGE !== 'production' ? `MDM [${STAGE}]` : 'MDM'}
        </Typography>
        <Typography style={{ color: '#fff' }}>ツ</Typography>
        <span className={this.props.classes.spacer} />
      </AppBar>
    );
  }
}

const MyAppBarStyled = withStyles(styles)(MyAppBar);

const MyLayout = (props) => (
  <Layout
    {...props}
    appBar={MyAppBarStyled}
    // menu={MyMenu}
    // notification={MyNotification}
  />
);

export default MyLayout;
