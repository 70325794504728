import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

export default function EditToolbarOnlySave(props: any) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}
