import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/CameraAlt';

export class QRCodeField extends Component<{
  fields: any;
  field: string;
}> {
  state = {
    error: '',
    open: false,
  };

  componentDidMount() {
    //console.log(this.props.fields);
  }

  handleScan = (data: any) => {
    if (data && this.props.fields[this.props.field]) {
      this.props.fields[this.props.field].props.onBlur(data);
    }
  };
  handleError = (err: any) => {
    this.setState({ error: err + '' });
  };
  handleButtonClick = (e: any) => {
    console.log(this.props.fields);
    this.setState({ open: !this.state.open });
  };
  render() {
    return (
      <React.Fragment>
        <Button
          onClick={this.handleButtonClick}
          variant="contained"
          style={{ marginBottom: '20px' }}
        >
          <CameraIcon /> {this.state.open ? 'Fechar QRCode' : 'Abrir QRCode'}
        </Button>
        {this.state.open && (
          <React.Fragment>
            {this.state.error && <p>{this.state.error}</p>}
            <QrReader
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '300px' }}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
