import { adminUrl, JWT_TOKEN_NAME } from '../config';

class S3UploadAndCreateApplication {
  async run(data) {
    if (!data || !data.files || !data.files.rawFile) {
      throw new Error('Invalid File`');
    }

    const signedPOST = await this.requestSignedUploadUrl();
    await this.uploadToS3(
      signedPOST.url,
      signedPOST.fields,
      data.files.rawFile
    );
    const created = await this.createApplication(signedPOST);

    return created;
  }

  async requestSignedUploadUrl() {
    return fetch(`${adminUrl}/mdm-applications/request-signed-upload-url`, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'bearer ' + localStorage.getItem(JWT_TOKEN_NAME),
      }),
    }).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  }

  async uploadToS3(url, fields, rawFile) {
    let form = new FormData();

    for (const key of Object.keys(fields)) {
      form.set(key, fields[key]);
    }

    form.set('file', rawFile);

    return fetch(url, {
      method: 'POST',
      body: form,
    }).then((response) => {
      /**
       * Response can be 200, 201 or 204
       * @see https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
       */
      if (response.status < 200 || response.status > 204) {
        throw new Error(response.statusText);
      }
      return true;
    });
  }

  async createApplication(signedPOST) {
    return fetch(`${adminUrl}/mdm-applications`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: 'bearer ' + localStorage.getItem(JWT_TOKEN_NAME),
      }),
      body: JSON.stringify(signedPOST),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => ({ data: { id: json.id } }));
  }
}

export const addApkUpload = (dataProvider: any) => {
  return {
    ...dataProvider,
    create: (resource, params) => {
      if (resource === 'mdm-applications') {
        return new S3UploadAndCreateApplication().run(params.data);
      }

      return dataProvider.create(resource, params);
    },
  };
};
