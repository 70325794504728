import React from 'react';
import {
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  maxValue,
  minValue,
  number,
  NumberInput,
  Pagination,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceManyField,
  required,
  SelectArrayInput,
  Show,
  SimpleForm,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import EditToolbarOnlySave from '../../components/EditToolbarOnlySave';
import { MdmDeviceGroupsDataGrid } from './mdm-device-groups';

// let fieldsRefs = {};

const ListFilter = (props) => (
  <Filter {...props}>
    <BooleanInput label="Mostrar removidos" source="deleted" alwaysOn />
  </Filter>
);

const mdmConfigurationsRowStyle = (record, index) => ({
  backgroundColor:
    record?.function === 'DUAL_CLIENT'
      ? '#D8F1FF'
      : record?.function === 'DUAL_CASHIER'
      ? '#FFFDB9'
      : '',
});

export const MdmConfigurationsDataGrid = (
  <Datagrid rowClick="show" rowStyle={mdmConfigurationsRowStyle}>
    <TextField source="name" />
    <TextField source="kioskApplicationPackageName" />
    <BooleanField source="mobileNetworkEnabled" />
    <BooleanField source="wifiEnabled" />
    <ReferenceArrayField
      label="Applications"
      reference="mdm-applications"
      source="applicationsIds"
    >
      <SingleFieldList linkType="show">
        <ChipField source="packageAndVersion" />
      </SingleFieldList>
    </ReferenceArrayField>
    <BooleanField source="deleted" />
    <DateField source="createdAt" locales="pt-BR" />
    {/* <DateField  source="updatedAt" locales="pt-BR" /> */}
    {/* <BooleanField source="deleted" /> */}
    {/* <TextField source="deletedAt" /> */}
  </Datagrid>
);

export const MdmConfigurationsList = (props: any) => (
  <List
    perPage={25}
    bulkActionButtons={false}
    filters={<ListFilter />}
    filterDefaultValues={{ deleted: false }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    {MdmConfigurationsDataGrid}
  </List>
);

export const MdmConfigurationsShow = (props: any) => (
  <Show title={<Title />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="kioskApplicationPackageName" />
        <BooleanField source="mobileNetworkEnabled" />
        <TextField source="apnNetwork" />
        <BooleanField source="wifiEnabled" />
        <TextField source="wifiNetworkName" />
        <TextField source="wifiNetworkPassword" />
        <TextField source="screenBrightness" />
        <ReferenceArrayField
          label="Applications"
          reference="mdm-applications"
          source="applicationsIds"
        >
          <SingleFieldList linkType="show">
            <ChipField source="packageAndVersion" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField source="deleted" />
        <TextField source="id" />
        <DateField source="createdAt" showTime={true} locales="pt-BR" />
        <DateField source="updatedAt" showTime={true} locales="pt-BR" />
      </Tab>
      <Tab label="Grupos" path="mdm-device-groups">
        <ReferenceManyField
          pagination={<Pagination />}
          reference="mdm-device-groups"
          target="configuration"
          addLabel={false}
        >
          {MdmDeviceGroupsDataGrid}
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const MdmConfigurationsCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm
      toolbar={<EditToolbarOnlySave />}
      defaultValue={{
        kioskApplicationPackageName: 'br.com.payface',
        mobileNetworkEnabled: true,
        apnNetwork: 'vodafone.br',
        screenBrightness: 90,
      }}
      redirect="show"
    >
      <TextInput source="name" validate={[required()]} />
      <TextInput source="kioskApplicationPackageName" />
      <BooleanInput
        addLabel
        label="Mobile Network Enabled"
        source="mobileNetworkEnabled"
      />
      <TextInput source="apnNetwork" />
      <BooleanInput addLabel label="Wifi Enabled" source="wifiEnabled" />
      <TextInput source="wifiNetworkName" />
      <TextInput source="wifiNetworkPassword" />
      <NumberInput source="screenBrightness" step={1} />
      <ReferenceArrayInput
        label="Applications"
        source="applicationsIds"
        reference="mdm-applications"
        filter={{ deleted: false }}
        perPage={100}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <SelectArrayInput optionText="packageAndVersion" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

const validateBrightness = [number(), minValue(10), maxValue(100)];

export const MdmConfigurationsEdit = (props: any) => (
  <Edit undoable={false} title={<Title />} {...props}>
    <SimpleForm redirect="show">
      <TextInput
        source="name"
        // defaultValue="Principal"
        validate={[required()]}
      />
      <TextInput source="kioskApplicationPackageName" />
      <BooleanInput
        addLabel
        label="Mobile Network Enabled"
        source="mobileNetworkEnabled"
      />
      <TextInput source="apnNetwork" />
      <BooleanInput addLabel label="Wifi Enabled" source="wifiEnabled" />
      <TextInput source="wifiNetworkName" />
      <TextInput source="wifiNetworkPassword" />
      <NumberInput
        source="screenBrightness"
        step={1}
        validate={validateBrightness}
      />
      <ReferenceArrayInput
        label="Applications"
        source="applicationsIds"
        reference="mdm-applications"
        filter={{ deleted: false }}
        perPage={100}
        sort={{ field: 'createdAt', order: 'DESC' }}
      >
        <SelectArrayInput optionText="packageAndVersion" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

const Title = ({ record }: any) => {
  return <span>Configuration {record ? `"${record.name}"` : ''}</span>;
};
